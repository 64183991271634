import React from "react";
import logo from "./header_web_desktop_-_ver2-1.png";
import "./App.css";
import HubspotForm from "react-hubspot-form";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <br />

                <p><img src={logo} alt="logo" className="responsive-image"/></p>

                <p><a href="https://www.facebook.com/groups/cursosrosariocom/" target="_blank"
                      rel="noopener noreferrer"
                      className="App-link">Sumate a nuestro Grupo de Facebook</a></p>

                <div className="fb-group" data-href="https://www.facebook.com/groups/cursosrosariocom/" data-width="280"
                     data-show-social-context="true" data-show-metadata="false"></div>

                <p></p>

                <p><a href="https://www.facebook.com/CursosRosario/" target="_blank"
                      rel="noopener noreferrer"
                      className="App-link">Seguí nuestra Página de Facebook</a></p>

                <div className="fb-page" data-href="https://www.facebook.com/CursosRosario" data-tabs="timeline"
                     data-width="" data-height="" data-small-header="true" data-adapt-container-width="true"
                     data-hide-cover="true" data-show-facepile="true">
                    <blockquote cite="https://www.facebook.com/CursosRosario" className="fb-xfbml-parse-ignore"><a
                        href="https://www.facebook.com/CursosRosario">CursosRosario.com</a></blockquote>
                </div>

                <p></p>

                {/* <p><strong>Estamos trabajando en una nueva versión.</strong></p> */}
                <p>Querés estar al tanto de las novedades? Dejanos tus datos!</p>

                <HubspotForm
                    portalId='5890131'
                    formId='d444dcf2-ea04-42a1-b425-7836be314161'
                    loading={<div>Cargando...</div>}
                />

                <br />

            </header>
        </div>
    );
}

export default App;
